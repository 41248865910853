/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

/////////////////////////////////////////////
 ////// Functions to manipulate cookies //////
 /////////////////////////////////////////////
 // cookie setter
 function setCookie(cname, cvalue, exdays) {
   var d = new Date();
   d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
   var expires = "expires="+d.toUTCString();
   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
 }

 // cookie getter
 function getCookie(cname) {
   var name = cname + "=";
   var ca = document.cookie.split(';');
   for(var i = 0; i < ca.length; i++) {
     var c = ca[i];
     while (c.charAt(0) == ' ') {
       c = c.substring(1);
     }
     if (c.indexOf(name) == 0) {
       return c.substring(name.length, c.length);
     }
   }
   return "";
 }



(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages




        //if(getCookie(0)){
          if($('.alert-bar__wrapper').length){
            var cookieValue = parseInt(getCookie('alertBarCookie'));
            if(cookieValue === 0){
              $('.alert-bar__wrapper').addClass('active');
            } else {
              $('.alert-bar__wrapper .alert-bar__close a').click(function(e){
                e.preventDefault();
                $('.alert-bar__wrapper').removeClass('active');
                setCookie('alertBarCookie',1,1);
              });
            }
          }
        //}

        // initialize the content item slideshow(s)
        if ($('.full-width-image-slideshow__slides').length) {
          $('.full-width-image-slideshow__slides').each(function(i,e){
            $(e).slick({
              autoplay: true,
              autoplaySpeed: 4000,
              arrows: false,
              dots: true,
              speed: 400,
            });
          });
        }

        // Initialize Slick Carousel
        if ($('.masthead-slideshow').length) {
          //var sliderId = $('.masthead-slideshow > div').attr("id");
          //$('#'+sliderId).slick({
          $('#masthead-slider').slick({
            autoplay: true,
            autoplaySpeed: 3500,
            prevArrow: $('.slick-prev-button'),
            nextArrow: $('.slick-next-button'),
            dots: true,
            fade: true,
            speed: 800,
            pauseOnHover: false,
          });
        }



        // initialize the landing page intro slideshow
        if ($('.landing-page-slideshow').length) {
          $('.landing-page-slideshow').slick({
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
            dots: true,
            speed: 1000
          });
        }


        $(window).scroll( function() {
          var topHeight = $('.sticky-wrapper').height();
          var footerHeight = $('.content-info').height();
          var height = $(this).scrollTop() + topHeight + 120;
          var docHeight = $(document).height() - footerHeight;
          var newHeight = docHeight - height;
          $('#landing-page-content-items-filter.stuck').css("height", newHeight);
        });
        // Scroll position top + sticky header
        // Scroll position bottom plus footer



        // initialize isotope for the searchable/filterable result item grid
        if ($('.isotope-grid').length) {
          $grid = $('.isotope-grid').isotope({
            itemSelector: '.isotope-item',
            getSortData: {
              publishDate: '[data-publish-date-ts] parseInt',
              title: '.result-teaser-title'
            }
          });

          $grid.on( 'arrangeComplete', function( event, filteredItems ) {
                  var str1 = $('.all-results-count').html();
                  var pattern = "Results";
                  var str2 = str1.substr(str1.indexOf(pattern), str1.length);
                  $('.all-results-count').html(filteredItems.length +" "+str2);
          });
        }



        // cause horizontal mousescroll for tag list under each content item in results section body on landing pages
        // if ($('.result-tag-container').length) {
        //   $('.result-tag-container').mousewheel(function(event, delta) {
        //     $(this).removeClass('add-white-right-side-blur');
        //     this.scrollLeft -= delta;
        //     if ( this.scrollLeft === 0 && !$(this).hasClass('add-white-right-side-blur') && $(this).children().length > 3 ) {
        //       $(this).addClass('add-white-right-side-blur');
        //     }
        //     event.preventDefault();
        //   });
        //
        //   $('.result-tag-container').scroll(function(e){
        //     $(this).removeClass('add-white-right-side-blur');
        //     var scrollLeftAmount = $(this).scrollLeft();
        //     if (scrollLeftAmount === 0 && !$(this).hasClass('add-white-right-side-blur') && $(this).children().length > 3) {
        //       $(this).addClass('add-white-right-side-blur');
        //     }
        //   });
        // }



        // Add sidebar-link class to all sidebar-links ul children
        if ($('.sidebar-links').length) {
          $('.sidebar-links').children('li').each(function(i,e){
            $(e).addClass('sidebar-link');
          });
        }



        // initialize Waypoints for landing pages
        if ($('#landing-page-content-items-filter').length) {
          var search_sticky = new Waypoint.Sticky({
            element: $('#landing-page-search-container')[0]
          });

          var filter_sticky = new Waypoint.Sticky({
            element: $('#landing-page-content-items-filter')[0],
            offset: 135,
            handler: function(direction){
              var windowWidth = $(window).width()+15;
              if (direction == 'down' && windowWidth >= 992) {
                var parentWidth = $(this.element).parent().width();
                var width = (parentWidth*0.25);
                var rightPos = ((windowWidth - parentWidth)/2);
                var beforeRight = 0;
                var beforeWidth = $(this.element).css("width");
                $(this.element).css({"right":rightPos+"px", "width":width+"px", "position":"fixed"});
              } else {
                $(this.element).removeAttr("style");
                $(this.element).css({"position":"static"});
              }
            }
          });
        }


        // initialize the filter items with correct dynamic height and click event
        if ($('.sidebar-filter-items-container').length) {
          var sidebarFilterItemsHeight = 0;
          var itemContainerWidth = $('#landing-page-content-items-filter').width();
          $('body').append('<div id="tempCloneTopics" style="width:'+itemContainerWidth+'px;"></div>');

          // iterate over the topic filter item containers
          $('.sidebar-filter-item-container--topic').each(function(i,e){
            // a little trick I learned in 'nam to get the computed height of any element
            // regardless of the height being specified in CSS. Works everywhere.
            var cloneHeight = $(e).clone().addClass('filter-item-clone--topic').appendTo('#tempCloneTopics').height();
            sidebarFilterItemsHeight += cloneHeight;
            $('.filter-item-clone--topic').remove();
          }).promise().done(function(){
            $('#tempCloneTopics').remove();
            $('#item-topic-filter-items').height(0);
            $('#item-topic-filter-items').css({'display':'block'});
            $('.sidebar-filter-title-container--topic').on('click', {'items_height': sidebarFilterItemsHeight}, function(e){
              var itemsContainer = $(this).data('itemsContainer');
              var itemsToggle = $(this).data('itemsToggle');
              if ($('#'+itemsContainer).hasClass('active')) {
                $('#'+itemsContainer).removeClass('active');
                $('#'+itemsToggle).removeClass('active');
                $('#'+itemsContainer).height(0);
              } else {
                $('#'+itemsContainer).addClass('active');
                $('#'+itemsToggle).addClass('active');
                $('#'+itemsContainer).height(e.data.items_height);
              }
            });
          });

          sidebarFilterItemsHeight = 0;
          $('body').append('<div id="tempCloneTags" style="width:'+itemContainerWidth+'px;"></div>');
          // iterate over the tag filter item containers
          $('.sidebar-filter-item-container--tag').each(function(i,e){
            // a little trick I learned in 'nam to get the computed height of any element
            // regardless of the height being specified in CSS. Works everywhere.
            var cloneHeight = $(e).clone().addClass('filter-item-clone--tag').appendTo('#tempCloneTags').height();
            sidebarFilterItemsHeight += cloneHeight;
            $('.filter-item-clone--tag').remove();
          }).promise().done(function(){
            $('#tempCloneTags').remove();
            $('#item-tag-filter-items').height(0);
            $('#item-tag-filter-items').css({'display':'block'});
            $('.sidebar-filter-title-container--tag').on('click', {'items_height': sidebarFilterItemsHeight}, function(e){
              var itemsContainer = $(this).data('itemsContainer');
              var itemsToggle = $(this).data('itemsToggle');
              if ($('#'+itemsContainer).hasClass('active')) {
                $('#'+itemsContainer).removeClass('active');
                $('#'+itemsToggle).removeClass('active');
                $('#'+itemsContainer).height(0);
              } else {
                $('#'+itemsContainer).addClass('active');
                $('#'+itemsToggle).addClass('active');
                $('#'+itemsContainer).height(e.data.items_height);
              }
            });
          });

          sidebarFilterItemsHeight = 0;
          $('body').append('<div id="tempClonePublishDates" style="width:'+itemContainerWidth+'px;"></div>');
          // iterate over the publish date filter item containers
          $('.sidebar-filter-item-container--publish-date').each(function(i,e){
            // a little trick I learned in 'nam to get the computed height of any element
            // regardless of the height being specified in CSS. Works everywhere.
            var cloneHeight = $(e).clone().addClass('filter-item-clone--publish-date').appendTo('#tempClonePublishDates').height();
            sidebarFilterItemsHeight += cloneHeight;
            $('.filter-item-clone--publish-date').remove();
          }).promise().done(function(){
            $('#tempClonePublishDates').remove();
            $('#publish-date-filter-items').height(0);
            $('#publish-date-filter-items').css({'display':'block'});
            $('.sidebar-filter-title-container--publish-date').on('click', {'items_height': sidebarFilterItemsHeight}, function(e){
              var itemsContainer = $(this).data('itemsContainer');
              var itemsToggle = $(this).data('itemsToggle');
              if ($('#'+itemsContainer).hasClass('active')) {
                $('#'+itemsContainer).removeClass('active');
                $('#'+itemsToggle).removeClass('active');
                $('#'+itemsContainer).height(0);
              } else {
                $('#'+itemsContainer).addClass('active');
                $('#'+itemsToggle).addClass('active');
                $('#'+itemsContainer).height(e.data.items_height);
              }
            });
          });
        }


        // add Isotope fancy filtering
        if ( $('.isotope-grid').length ) {
         /* $checkboxes = $('.sidebar-filter-checkbox');
          $checkboxes.change(function(){
            var inclusives = [];
            $checkboxes.each(function(i,e){
              var justForRedrawPurposes = e.offsetHeight;
              if (e.checked) {
                inclusives.push(e.value);
              }
              var filterValue = inclusives.length ? inclusives.join(', ') : '*';
              $grid.isotope({ filter: filterValue });
            });
          });*/


         /*$checkboxes = $('.sidebar-filter-checkbox');
          var filters = [];
          $checkboxes.change(function(){
            var $this = $(this);
            //var inclusives = [];
            var newFilter= $this.val();
            if($this.is(":checked")){
                filters.push(newFilter);
            }else{
                var found = jQuery.inArray(newFilter, filters);
                if (found >= 0) {
                    // Element was found, remove it.
                    filters.splice(found, 1);
                }
            }
            var filterValue = filters.join(', ');// filters.length > 0 ? filters.join(', ') : '*';
            $grid.isotope({ filter: filterValue });
            console.log("filtros -rose: "+filterValue );
          });*/
        $container = $('.isotope-grid');
        $checkboxes = $('.sidebar-filter-checkbox');
        $checkboxes.change(function(){
            var filters = [];
            // get checked checkboxes values
            $checkboxes.filter(':checked').each(function(){
              filters.push( this.value );
            });

            filters = filters.join(', ');
            $container.isotope({ filter: filters });
            console.log("filtros-example: "+filters );
        });

          $sortDropdown = $('#all-results-sort');
          $sortDropdown.change(function(e){
            var sortByVal = e.target.options[e.target.selectedIndex].getAttribute('data-sort-by');
            var sortAscendingVal = e.target.options[e.target.selectedIndex].getAttribute('data-sort-ascending');
            if ( sortAscendingVal == 'false' ) {
              sortAscendingVal = false;
            } else {
              sortAscendingVal = true;
            }
            $grid.isotope({sortBy: sortByVal, sortAscending: sortAscendingVal});
          });

          $searchInput = $('#content-item-keyword-search');
          $searchInput.on('input',function(){
            var qsRegex = new RegExp( $(this).val(), 'gi' );
            $grid.isotope({filter:function(){ return qsRegex ? $(this).text().match( qsRegex ) : 'true'; }});
          });
        }


        // add scroll to functionality to landing page advanced search button
        if ($('#advanced-search-scroll-to').length) {
          $('#advanced-search-scroll-to').on('click',function(e){
            e.preventDefault();
            $(window).scrollTo('#content-item-keyword-search-form', {
              duration: 400
            });
          });
        }



        // any and all window resize events
        $(window).resize(function(e){
          var windowWidth = $(window).width()+15;
          if ($('#landing-page-content-items-filter').length && $('#landing-page-content-items-filter').hasClass('stuck') && windowWidth >= 992) {
            var parentWidth = (windowWidth >= 1200) ? 1170 : 970;
            var width = (parentWidth*0.25);
            var rightPos = ((windowWidth - parentWidth)/2);
            $('#landing-page-content-items-filter').css({"right":rightPos+"px", "width":width+"px", "position":"fixed"});
          } else if ($('#landing-page-content-items-filter').length && $('#landing-page-content-items-filter').hasClass('stuck') && windowWidth < 992) {
            $(this.element).removeAttr("style");
            $(this.element).css({"position":"static"});
          }

          // make sure filter items have enough height alloted in container
          var sidebarFilterItemsHeight = 0;
          var itemContainerWidth = $('#landing-page-content-items-filter').width();
          $('body').append('<div id="tempCloneTopic" style="width:'+itemContainerWidth+'px;"></div>');

          // iterate over the topic filter items
          $('.sidebar-filter-item-container--topic').each(function(i,e){
            // a little trick I learned in 'nam to get the computed height of any element
            // regardless of the height being specified in CSS. Works everywhere.
            var cloneHeight = $(e).clone().addClass('filter-item-clone--topic').appendTo('#tempCloneTopic').height();
            sidebarFilterItemsHeight += cloneHeight;
            $('.filter-item-clone--topic').remove();
          }).promise().done(function(){
            $('#tempCloneTopic').remove();
            if ($('#item-topic-filter-items').hasClass('active')) {
              $('#item-topic-filter-items').height(sidebarFilterItemsHeight);
            } else {
              $('#item-topic-filter-items').height(0);
            }
            $('#item-topic-filter-items').css({'display':'block'});
            $('.sidebar-filter-title-container--topic').off('click');
            $('.sidebar-filter-title-container--topic').on('click', {'items_height': sidebarFilterItemsHeight}, function(e){
              var itemsContainer = $(this).data('itemsContainer');
              var itemsToggle = $(this).data('itemsToggle');
              if ($('#'+itemsContainer).hasClass('active')) {
                $('#'+itemsContainer).removeClass('active');
                $('#'+itemsToggle).removeClass('active');
                $('#'+itemsContainer).height(0);
              } else {
                $('#'+itemsContainer).addClass('active');
                $('#'+itemsToggle).addClass('active');
                $('#'+itemsContainer).height(e.data.items_height);
              }
            });
          });

          sidebarFilterItemsHeight = 0;
          $('body').append('<div id="tempCloneTag" style="width:'+itemContainerWidth+'px;"></div>');
          // iterate over the tag filter items
          $('.sidebar-filter-item-container--tag').each(function(i,e){
            // a little trick I learned in 'nam to get the computed height of any element
            // regardless of the height being specified in CSS. Works everywhere.
            var cloneHeight = $(e).clone().addClass('filter-item-clone--tag').appendTo('#tempCloneTag').height();
            sidebarFilterItemsHeight += cloneHeight;
            $('.filter-item-clone--tag').remove();
          }).promise().done(function(){
            $('#tempCloneTag').remove();
            if ($('#item-tag-filter-items').hasClass('active')) {
              $('#item-tag-filter-items').height(sidebarFilterItemsHeight);
            } else {
              $('#item-tag-filter-items').height(0);
            }
            $('#item-tag-filter-items').css({'display':'block'});
            $('.sidebar-filter-title-container--tag').off('click');
            $('.sidebar-filter-title-container--tag').on('click', {'items_height': sidebarFilterItemsHeight}, function(e){
              var itemsContainer = $(this).data('itemsContainer');
              var itemsToggle = $(this).data('itemsToggle');
              if ($('#'+itemsContainer).hasClass('active')) {
                $('#'+itemsContainer).removeClass('active');
                $('#'+itemsToggle).removeClass('active');
                $('#'+itemsContainer).height(0);
              } else {
                $('#'+itemsContainer).addClass('active');
                $('#'+itemsToggle).addClass('active');
                $('#'+itemsContainer).height(e.data.items_height);
              }
            });
          });

          sidebarFilterItemsHeight = 0;
          $('body').append('<div id="tempClonePublishDate" style="width:'+itemContainerWidth+'px;"></div>');
          // iterate over the publish date filter items
          $('.sidebar-filter-item-container--publish-date').each(function(i,e){
            // a little trick I learned in 'nam to get the computed height of any element
            // regardless of the height being specified in CSS. Works everywhere.
            var cloneHeight = $(e).clone().addClass('filter-item-clone--publish-date').appendTo('#tempClonePublishDate').height();
            sidebarFilterItemsHeight += cloneHeight;
            $('.filter-item-clone--publish-date').remove();
          }).promise().done(function(){
            $('#tempClonePublishDate').remove();
            if ($('#publish-date-filter-items').hasClass('active')) {
              $('#publish-date-filter-items').height(sidebarFilterItemsHeight);
            } else {
              $('#publish-date-filter-items').height(0);
            }
            $('#publish-date-filter-items').css({'display':'block'});
            $('.sidebar-filter-title-container--publish-date').off('click');
            $('.sidebar-filter-title-container--publish-date').on('click', {'items_height': sidebarFilterItemsHeight}, function(e){
              var itemsContainer = $(this).data('itemsContainer');
              var itemsToggle = $(this).data('itemsToggle');
              if ($('#'+itemsContainer).hasClass('active')) {
                $('#'+itemsContainer).removeClass('active');
                $('#'+itemsToggle).removeClass('active');
                $('#'+itemsContainer).height(0);
              } else {
                $('#'+itemsContainer).addClass('active');
                $('#'+itemsToggle).addClass('active');
                $('#'+itemsContainer).height(e.data.items_height);
              }
            });
          });
        });



        // mobile nav
        // handle menu toggle clicks
        $('.mobile-nav-toggle, .mobile-nav-toggle.fa-bars').on('click', function(e){
          e.preventDefault();
          var target = e.target || e.srcElement;
          if ($(target).hasClass('fa-bars')) {
            target = $(target).closest('.mobile-nav-toggle');
          }
          $('.nav-mobile').toggleClass('expanded');
        });
        // add a dropdown button to any menu item that has children
        $('.menu-item-has-children > a').after('<span class="mobile-menu-dropdown"><i class="fa fa-angle-down"></i></span>');
        $('.mobile-menu-dropdown').on('click', function(e){

          var target = e.target || e.srcElement;
          var subMenu = $(target).closest('.menu-item').children('.sub-menu');

          if (subMenu.height() > 0) {
            // if the submenu has any height, collapse it
            $('.sub-menu').css({"height":"0px"});
          }
          else {
            // otherwise, give it 26px of height for every menu-item it contains
            $('.sub-menu').css({"height":"0px"});
            var childLinkCount = subMenu.children('.menu-item').length;
            var heightToAdd = childLinkCount * 26;
            subMenu.css({"height":heightToAdd+"px"});
          }
        });



        // Disable the "Add another photo" after the secondary file uploader is shown
        $('#photo-contest-form__button__add-another-photo').on('click', function(e){
          e.preventDefault();
          var target = e.target || e.srcElement;
          $('#photo-contest-form__file-input__photo-upload-2, #photo-contest-form__text-input__photo-caption-2, #photo-contest-form__textarea__photographers-statement-2').parent().removeClass('hidden');
          $(target).attr('disabled', 'disabled');
        });

        var progressHandler = function(e,fileUploadId){
          if (e.lengthComputable) {
            var percentLoaded = Math.round((e.loaded/e.total)*100);
            $('#progress-bar-'+fileUploadId+'-inner').css({"width":percentLoaded+"%"});

            if (e.loaded !== e.total) {
              if ($('#uploadSuccessMessage'+fileUploadId).length) {
                $('#uploadSuccessMessage'+fileUploadId).text(percentLoaded+"%");
              }
              else {
                $('#progress-bar-'+fileUploadId+'-inner').append('<span id="uploadSuccessMessage'+fileUploadId+'">'+percentLoaded+'%</span>');
              }
            }
          }
        };

        $('.photo-contest-form__input').on('input', function(e){
          var target = e.target || e.srcElement;
          if ($(target).hasClass('error')) {
            $(target).removeClass('error');
            $(target).next('p').text('');
          }
        });

        // Handle validation and AJAX submittal of the file uploads
        $('#photo-contest-form__file-input__photo-upload-1, #photo-contest-form__file-input__photo-upload-2').on('change', function(e){

          var file = $(this).prop("files")[0];
          var name = file.name;
          var size = file.size;
          var type = file.type;

          // validation


          // ajax upload
          // get the number of the upload input element from the id attr
          var fileUploadId = $(this).attr('id');
          fileUploadId = fileUploadId.substr(fileUploadId.length - 1);

          // preliminary cleanup in case this is not the first upload for this input[type="file"]
          if ($('#progress-bar-'+fileUploadId).hasClass('success')) {
            $('#progress-bar-'+fileUploadId).removeClass('success');
          }

          if ($('#progress-bar-'+fileUploadId).hasClass('error')) {
            $('#progress-bar-'+fileUploadId).removeClass('error');
          }

          // get rid of any validation error messages
          if ($('#photo-contest-form__file-input__photo-upload-1').hasClass('error')) {
            $('#photo-contest-form__file-input__photo-upload-1').removeClass('error');
            $('#photo-contest-form__message__error__photo-upload-1').text('');
          }

          $('#progress-bar-'+fileUploadId+'-inner').css({"width":"0%"});
          $('#uploadSuccessMessage'+fileUploadId).remove();
          $('#progress-bar-'+fileUploadId+'-container').css({"display":"block"});
          $('#progress-bar-'+fileUploadId+'-inner').css({"display":"block"});

          var formData = new FormData();
          formData.append("file", $(this).prop("files")[0]);
          formData.append("action", "arbor_day_photo_entry_form_process");
          formData.append("security", arbor_day_photo_contest_form.nonce);
          formData.append("uploadNumber", fileUploadId);
          formData.append("submitType", "imageOnly");

          $.ajax({
            url: arbor_day_photo_contest_form.ajaxUrl,
            type: 'POST',
            dataType: "json",
            data: formData,
            contentType: false, // workaround 1
            processData: false, // workaround 2
            cache: false, // no cache
            xhr: function() {
              var myXhr = $.ajaxSettings.xhr();
              if (myXhr.upload) { // check if upload property exists
                myXhr.upload.addEventListener('progress', function(e){progressHandler(e,fileUploadId);}, false);
              }
              return myXhr;
            },
            beforeSend: function() {
              $('#photo-contest-form__file-input__photo-upload-'+fileUploadId).css({"border-bottom-left-radius":"0px","border-bottom-right-radius":"0px"});
              $('#photo-contest-form__file-input__photo-upload-'+fileUploadId).parent().css({"padding-bottom":"0px"});
            },
            success: function(response) {
              $('#uploadSuccessMessage'+fileUploadId).remove();
              $('#errorMessage'+fileUploadId).remove();

              // not successful
              if (response.status !== 'success') {
                $('#progress-bar-'+fileUploadId).addClass('error');
                $('#progress-bar-'+fileUploadId+'-inner').append('<span id="uploadSuccessMessage'+fileUploadId+'">UPLOAD FAILURE</span>');

                if (response.status === 'fail') {
                  $('#photo-display-'+fileUploadId).parent().css({"display":"none"});
                  for (var prop in response.data) {
                    $('#progress-bar-'+fileUploadId).after('<span id="errorMessage'+fileUploadId+'">'+response.data[prop]+'</span>');
                  }
                }
                else if (response.status === 'error') {
                  $('#photo-display-'+fileUploadId).parent().css({"display":"none"});
                  $('#progress-bar-'+fileUploadId).after('<span id="errorMessage'+fileUploadId+'">'+response.message+'</span>');
                }
              }
              // successful
              else {
                $('#progress-bar-'+fileUploadId).addClass('success');
                $('#progress-bar-'+fileUploadId+'-inner').append('<span id="uploadSuccessMessage'+fileUploadId+'">UPLOAD SUCCESS</span>');
                $('#photo-display-'+fileUploadId).attr('src', response.data.location);
                $('#photo-display-'+fileUploadId).parent().css({"display":"block"});
                $('#photo-display-'+fileUploadId).data("visible","true");
              }
            },
            error: function(response) {
              // console.log(response);
            }
          });
        });

        // Handle the AJAX form submission
        $('#photo-contest-form__button__submit').on('click', function(e){
          e.preventDefault();
          var target = e.target || e.srcElement;
          var formData = new FormData($('#photo-contest-form').get(0));
          formData.append("action", "arbor_day_photo_entry_form_process");
          formData.append("security", arbor_day_photo_contest_form.nonce);
          formData.append("submitType", "fullForm");

          // some form validation
          var validated = true;

          var textInputNameArray = ["first-name","last-name","school-name","school-town","teacher-name","teacher-email-address","grade","photo-caption-1","photographers-statement-1","photo-caption-2","photographers-statement-2"];
          var errorMessage = '';

          for (var i = textInputNameArray.length - 1; i >= 0; i--) {
            var inputVal = '';
            if (textInputNameArray[i] === 'photographers-statement-1' || textInputNameArray[i] === 'photographers-statement-2') {
              inputVal = $('#photo-contest-form__textarea__'+textInputNameArray[i]).val();
            }
            else {
              inputVal = $('#photo-contest-form__text-input__'+textInputNameArray[i]).val();
            }
            if (textInputNameArray[i] === 'first-name' || textInputNameArray[i] === 'last-name' || textInputNameArray[i] === 'school-name' || textInputNameArray[i] === 'school-town' || textInputNameArray[i] === 'teacher-name') {
              if (inputVal === '' || inputVal === null || inputVal === 'undefined') {
                validated = false;
                $('#photo-contest-form__text-input__' + textInputNameArray[i]).addClass('error');
                var splitArray = textInputNameArray[i].split("-");
                for (var k = splitArray.length - 1; k >= 0; k--) {
                  var f = splitArray[k].charAt(0).toUpperCase();
                  splitArray[k] = f + splitArray[k].substr(1);
                }
                var fieldName = splitArray.join(" ");
                errorMessage = fieldName + " is required";
                $('#photo-contest-form__message__error__'+textInputNameArray[i]).text(errorMessage);
              }
            }
            else if (textInputNameArray[i] === 'teacher-email-address') {
              if (!/(.+)@(.+){2,}\.(.+){2,}/.test(inputVal)) {
                validated = false;
                $('#photo-contest-form__text-input__' + textInputNameArray[i]).addClass('error');
                errorMessage = "Please enter a valid email address";
                $('#photo-contest-form__message__error__teacher-email-address').text(errorMessage);
              }
            }
            else if (textInputNameArray[i] === 'grade') {
              inputVal = parseInt(inputVal,10);
              if (inputVal > 12 || inputVal < 5 || inputVal === '' || inputVal === null || inputVal === 'undefined' || isNaN(inputVal)) {
                validated = false;
                $('#photo-contest-form__text-input__' + textInputNameArray[i]).addClass('error');
                errorMessage = "Please input a Grade between 5 and 12";
                $('#photo-contest-form__message__error__' + textInputNameArray[i]).text(errorMessage);
              }
            }
            else if (textInputNameArray[i] === 'photo-caption-1') {
              if ($('#photo-display-1').data("visible") === 'true') {
                // if a photo has been uploaded, make sure the photo caption is present and not blank
                if (inputVal === '' || inputVal === null || inputVal === 'undefined') {
                  validated = false;
                  $('#photo-contest-form__text-input__' + textInputNameArray[i]).addClass('error');
                  errorMessage = "Please add a Caption";
                  $('#photo-contest-form__message__error__' + textInputNameArray[i]).text(errorMessage);
                }
              }
            }
            else if (textInputNameArray[i] === 'photographers-statement-1') {
              if ($('#photo-display-1').data("visible") === 'true') {
                // if a photo has been uploaded, make sure the photo caption is present and not blank
                if (inputVal === '' || inputVal === null || inputVal === 'undefined') {
                  validated = false;
                  $('#photo-contest-form__textarea__' + textInputNameArray[i]).addClass('error');
                  errorMessage = "Please add a Photographer's Statement";
                  $('#photo-contest-form__message__error__' + textInputNameArray[i]).text(errorMessage);
                }
              }
            }
            else if (textInputNameArray[i] === 'photo-caption-2') {
              if ($('#photo-display-2').data("visible") === 'true') {
                // if a photo has been uploaded, make sure the photo caption is present and not blank
                if (inputVal === '' || inputVal === null || inputVal === 'undefined') {
                  validated = false;
                  $('#photo-contest-form__text-input__' + textInputNameArray[i]).addClass('error');
                  errorMessage = "Please add a Caption";
                  $('#photo-contest-form__message__error__' + textInputNameArray[i]).text(errorMessage);
                }
              }
            }
            else if (textInputNameArray[i] === 'photographers-statement-2') {
              if ($('#photo-display-2').data("visible") === 'true') {
                // if a photo has been uploaded, make sure the photo caption is present and not blank
                if (inputVal === '' || inputVal === null || inputVal === 'undefined') {
                  validated = false;
                  $('#photo-contest-form__textarea__' + textInputNameArray[i]).addClass('error');
                  errorMessage = "Please add a Photographer's Statement";
                  $('#photo-contest-form__message__error__' + textInputNameArray[i]).text(errorMessage);
                }
              }
            }
          }

          if ($('#photo-contest-form__file-input__photo-upload-1').val() === '' || $('#photo-contest-form__file-input__photo-upload-1').val() === 'undefined' || $('#photo-contest-form__file-input__photo-upload-1').val() === null) {
            validated = false;
            $('#photo-contest-form__file-input__photo-upload-1').addClass('error');
            errorMessage = "Please attach a photo to submit";
            $('#photo-contest-form__message__error__photo-upload-1').text(errorMessage);
          }

          if (validated) {
            $.ajax({
              url: arbor_day_photo_contest_form.ajaxUrl,
              type: 'POST',
              dataType: "json",
              data: formData,
              contentType: false, // workaround 1
              processData: false, // workaround 2
              cache: false, // no cache
              success: function(response) {
                if (response.status === 'success') {
                  $('#photo-contest-form__message__submit-status').text(response.data.message);
                }
                else if (response.status === 'fail') {
                  var failMessage = '';
                  for (var prop in response.data) {
                    failMessage = response.data[prop];
                  }

                  if ($('#photo-contest-form__message__submit-status').hasClass('success')) {
                    $('#photo-contest-form__message__submit-status').removeClass('success');
                  }

                  if ($('#photo-contest-form__message__submit-status').hasClass('error')) {
                    $('#photo-contest-form__message__submit-status').text(failMessage);
                  }
                  else {
                    $('#photo-contest-form__message__submit-status').addClass('error');
                    $('#photo-contest-form__message__submit-status').text(failMessage);
                  }
                }
                else if (response.status === 'error') {
                  var errorMessage = response.message;

                  if ($('#photo-contest-form__message__submit-status').hasClass('success')) {
                    $('#photo-contest-form__message__submit-status').removeClass('success');
                  }

                  if ($('#photo-contest-form__message__submit-status').hasClass('error')) {
                    $('#photo-contest-form__message__submit-status').text(errorMessage);
                  }
                  else {
                    $('#photo-contest-form__message__submit-status').addClass('error');
                    $('#photo-contest-form__message__submit-status').text(errorMessage);
                  }
                }
                // var responseMessage = response.data.message;
                // $('#photo-contest-form__message__submit-status').text(responseMessage);
              },
              error: function(response) {
                var responseMessage = "There was an error, please try again";
                if ($('#photo-contest-form__message__submit-status').hasClass('success')) {
                  $('#photo-contest-form__message__submit-status').removeClass('success');
                }

                if ($('#photo-contest-form__message__submit-status').hasClass('error')) {
                  $('#photo-contest-form__message__submit-status').text(responseMessage);
                }
                else {
                  $('#photo-contest-form__message__submit-status').addClass('error').text(responseMessage);
                }
              }
            });
          }
        });





        if ($('.photo-contest-voting-form').length) {

          $('.vote-checkbox').on('change', function(e){
            var target = e.target || e.srcElement;

            if ($(target).is(':checked')) {
              $(target).next().text('Selected');
            }
            else {
              $(target).next().text('Vote');
            }

          });

          $('.basic-input').on('input',function(e){
            var target = e.target || e.srcElement;
            if ($(target).hasClass('error')) {
              $(target).removeClass('error');
            }
          });

          $('.btn-vote-submit').on('click', function(e){
            e.preventDefault();
            var validated = true;
            var firstName = $('#voting-first-name').val();
            var lastName = $('#voting-last-name').val();
            var emailAddress = $('#voting-email').val();
            var votesObj = { "firstName":firstName, "lastName":lastName, "emailAddress":emailAddress, "postIds":{} };
            var errorMessage = '';

            if (typeof firstName === undefined || firstName === null || firstName === '') {
              $('#voting-first-name').addClass('error');
              validated = false;
              errorMessage = 'Please provide a first name';
            }

            if (typeof lastName === undefined || lastName === null || lastName === '') {
              $('#voting-last-name').addClass('error');
              validated = false;
              errorMessage = 'Please provide a last name';
            }

            if (typeof emailAddress === undefined || emailAddress === null || emailAddress === '') {
              $('#voting-email').addClass('error');
              validated = false;
              errorMessage = 'Please provide an email address';
            }
            else if (!/(.+)@(.+){2,}\.(.+){2,}/.test(emailAddress)) {
              $('#voting-email').addClass('error');
              validated = false;
              errorMessage = 'Please provide a valid email address';
            }

            $('.vote-checkbox:checked').each(function(i,e){
              var postId = $(e).data('postId');
              votesObj.postIds[i] = postId;
            });

            if (validated) {
              $.ajax({
                url: arbor_day_voting_form.ajaxUrl,
                method: "POST",
                dataType: "json",
                data: { "json": votesObj, "action":"arbor_day_vote_form_process", "security":arbor_day_voting_form.nonce },
                beforeSend: function () {
                  $('#photo-contest-voting-feedback-text').removeClass('photo-contest-voting-submission-response-hidden').addClass('photo-contest-voting-submission-response-thinking').html('<i class="fa fa-spinner fa-pulse fa-2x"></i>');
                },
                success: function(data, textStatus, jqXHR){
                  if ( data.status === 'success' ) {
                    $('#photo-contest-voting-feedback-text').removeClass('photo-contest-voting-submission-response-error');
                    $('#photo-contest-voting-feedback-text').addClass('photo-contest-voting-submission-response-success').html(data.data.message);
                  }
                  else if ( data.status === 'error' ) {
                    $('#photo-contest-voting-feedback-text').removeClass('photo-contest-voting-submission-response-success');
                    $('#photo-contest-voting-feedback-text').addClass('photo-contest-voting-submission-response-error').html(data.message);
                  }

                  $('.vote-checkbox').attr('checked', false);
                  $('.vote-checkbox').next().text('Vote');
                  $('#voting-first-name').val('');
                  $('#voting-last-name').val('');
                  $('#voting-email').val('');

                },
                error: function(jqXHR, textStatus, errorThrown){
                  console.log(jqXHR);
                  console.log(textStatus);
                  console.log(errorThrown);
                }
              });
            }
            else {
              $('#photo-contest-voting-feedback-text').addClass('photo-contest-voting-submission-response-error').html(errorMessage);
            }
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
